module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70,"duration":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-4/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"showCaptions":true,"linkImagesToOriginal":false,"loading":"lazy","markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","enableIdentityWidget":false,"publicPath":"admin","htmlTitle":"PIRATE CMS","htmlFavicon":"static/assets/logo.svg","includeRobots":false,"logo_url":"https://pirateweb.org/assets/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Meme Genes","short_name":"Meme Genes","start_url":"/?pro=true","description":"Do these memes make my butt look fat?","background_color":"transparent","lang":"en","theme_color":"transparent","display":"standalone","icon":"static/assets/logo.svg","crossOrigin":"use-credentials","theme_color_in_head":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"666fba6beb17dbd95c6798b6682ea902"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
